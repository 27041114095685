import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Star from "../assets/icons/star.svg";

function Review() {
    return (
        <div style={{ background: "#f3f3f3" }}>
            <div class="container">
                <div className="text-center pt-5">
                    <p>REVIEWS</p>
                    <h1>Hear Why Customers Choose Quantum Capital</h1>
                </div>
                <div className="mt-5">
                    <div class="elementor-section py-5" style={{ background: "#f0f9f4" }}>
                        <div className="container d-flex flex-wrap justify-content-around">
                            <div>
                                <img src={"/assets/img/upload/a.png"} width="150px" className="pt-4" />
                            </div>
                            <div>
                                <img src={"/assets/img/upload/consumer.png"} width="150px" className="pt-4" />
                            </div>
                            <div>
                                <img src={"/assets/img/upload/google.png"} width="150px" className="pt-4" />
                            </div>
                            <div>
                                <img src={"/assets/img/upload/trustlink.png"} width="150px" className="pt-4" />
                            </div>
                            <div>
                                <img src={"/assets/img/upload/trustpilot.png"} width="150px" className="pt-4" />
                            </div>
                        </div>
                    </div>

                </div>
                <div className="row py-5">
                    <div className="col-sm-3"></div>
                    <div className="col-sm-6">
                        <div class="d-flex mb-3">
                            <img src={Star} style={{ marginRight: "6px" }} />
                            <img src={Star} style={{ marginRight: "6px" }} />
                            <img src={Star} style={{ marginRight: "6px" }} />
                            <img src={Star} style={{ marginRight: "6px" }} />
                            <img src={Star} style={{ marginRight: "6px" }} />
                        </div>
                        <h2>Our Investment is in Good Hands. </h2>
                        <p className="p-small">We are very impressed with the professionalism, knowledgeability, and promptness with which Quantum Capital handled our investment from our savings and retirement. They have shown that they are well organized. They have also amply demonstrated great patience in fielding our questions. We are confident our investment in crypto is in good hands with Quantum Capital.</p>
                        <h4>Micheal</h4>
                        <p>Reviewed on: Oct 13th, 2022</p>
                        <p><FontAwesomeIcon icon={faCheckCircle} className="text-warning" /> Verified Customer</p>
                        <img src={"/assets/img/upload/google.png"} width="120px" />
                    </div>
                    <div className="col-sm-3"></div>
                </div>
                <div className="row py-5">
                    <div className="col-sm-3"></div>
                    <div className="col-sm-6">
                        <div class="d-flex mb-3">
                            <img src={Star} style={{ marginRight: "6px" }} />
                            <img src={Star} style={{ marginRight: "6px" }} />
                            <img src={Star} style={{ marginRight: "6px" }} />
                            <img src={Star} style={{ marginRight: "6px" }} />
                            <img src={Star} style={{ marginRight: "6px" }} />
                        </div>
                        <h2>The Experience Was Expectional. </h2>
                        <p className="p-small">Every person I’ve spoken with has been professional and helpful. Every question I have had had been answered and every step is explained. I appreciate that they have worked around my schedule for phone calls and the process was much smoother than I ever expected it to be and takes the stress out of such a big financial decision.</p>
                        <h4>Luis</h4>
                        <p>Reviewed on: Oct 13th, 2022</p>
                        <p><FontAwesomeIcon icon={faCheckCircle} className="text-warning" /> Verified Customer</p>
                        <img src={"/assets/img/upload/trustpilot.png"} width="120px" />
                    </div>
                    <div className="col-sm-3"></div>
                </div>
                <div className="row py-5">
                    <div className="col-sm-3"></div>
                    <div className="col-sm-6">
                        <div class="d-flex mb-3">
                            <img src={Star} style={{ marginRight: "6px" }} />
                            <img src={Star} style={{ marginRight: "6px" }} />
                            <img src={Star} style={{ marginRight: "6px" }} />
                            <img src={Star} style={{ marginRight: "6px" }} />
                            <img src={Star} style={{ marginRight: "6px" }} />
                        </div>
                        <h2>Professional and Helpful . </h2>
                        <p className="p-small">Every person I’ve spoken with has been professional and helpful. Every question I have had had been answered and every step is explained. I appreciate that they have worked around my schedule for phone calls and the process was much smoother than I ever expected it to be and takes the stress out of such a big financial decision.</p>
                        <h4>Luis</h4>
                        <p>Reviewed on: Oct 13th, 2022</p>
                        <p><FontAwesomeIcon icon={faCheckCircle} className="text-warning" /> Verified Customer</p>
                        <img src={"/assets/img/upload/consumer.png"} width="120px" />
                    </div>
                    <div className="col-sm-3"></div>
                </div>
            </div>
        </div >
    );
}

export default Review;