import About from "../pages/About";
import Contact from "../pages/Contact";
import Home from "../pages/Home";
import Review from "../pages/Review";

 const route = [
    {
        path: "/",
        routeProps:{
            exact: true
        },
        page: <Home />,
        meta: {
            title: "Home"
        }
    },
    {
        path: "/about",
        routeProps:{
            exact: true
        },
        page: <About />,
        meta: {
            title: "About"
        }
    },
    {
        path: "/review",
        routeProps:{
            exact: true
        },
        page: <Review />,
        meta: {
            title: "Review"
        }
    },
    {
        path: "/contact",
        routeProps:{
            exact: true
        },
        page: <Contact />,
        meta: {
            title: "Contact"
        }
    }
];


export default route;