import { Provider } from "react-redux";
import Footer from "../components/Footer";
import Header from "../components/Header";
import SetMeta from "../components/SetMeta";
import AppContext from "../contexts/AppContext";
import AppStore from "../stores/AppStore";

function AppLayout(props) {
  const { page, metaData } = props;
    
  return (
    <Provider store={AppStore}>
      <AppContext.Provider value="">
          <div>
            <SetMeta metaData={metaData}/>
            <Header/>
            {page}
            <Footer/>
        </div>
      </AppContext.Provider>
    </Provider>
  )

}

export default AppLayout;
