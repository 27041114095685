import { faAngleDown, faHamburger, faPhone, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Logo from "../assets/img/logo.png";
import { toggleMobileMenu } from "../slices/AppReducer";
import  "../styles/header.scss";

function Header() {
    const dispatch = useDispatch();
    const openMobileMenu = useSelector((state)=>state.app.openMobileMenu);
    return (
        <header className={`${openMobileMenu ? "active" : ""}`}>
            <div className="logo">
                <img src={Logo} width="70px"/>
            </div>
            <nav >
                <ul>
                    {/* <li>
                        <a href="#">Precious Metals IRA <FontAwesomeIcon icon={faAngleDown} style={{ marginLeft: "3px" }} /> </a>
                        <ul>
                            <li><a href="">Gold IRA</a></li>
                            <li><a href="">Sliver IRA</a></li>
                        </ul>
                    </li> */}
                    <li>
                        <Link to="/" onClick={(e)=>dispatch(toggleMobileMenu())}>Home</Link>
                    </li>
                    <li>
                        <Link to="/review" onClick={(e)=>dispatch(toggleMobileMenu())}>Reviews</Link>
                    </li>
                    <li>
                        <Link to="/about" onClick={(e)=>dispatch(toggleMobileMenu())}>About</Link>
                    </li>
                    <li>
                        <Link to="/contact" onClick={(e)=>dispatch(toggleMobileMenu())}>Contact Us</Link>
                    </li>
                    {/* <li>
                        <a href="#">About  <FontAwesomeIcon icon={faAngleDown} style={{ marginLeft: "3px" }} /></a>
                        <ul>
                            <li><a href="">Why Goldco</a></li>
                            <li><a href="">Careers</a></li>
                        </ul>
                    </li> */}
                   
                </ul>
            </nav>
            <div className="nav-button">
                <a className="btn btn-success py-2" href="https://web10.quantumcapital-inv.com/"  style={{width:"140px"}}>
                    <span style={{ marginRight: "3px" }}>
                        <FontAwesomeIcon icon={faPhone} />
                    </span>
                    Get Started
                </a>
                <a className="btn btn-warning py-2" style={{width:"140px"}} href="https://web10.quantumcapital-inv.com/">
                    Connect Wallet
                </a>
            </div>
            <div className="menu-toggle" onClick={(e)=>dispatch(toggleMobileMenu())}>
                {
                   openMobileMenu ?  
                   <FontAwesomeIcon icon={faTimes}/> :
                   <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
width="30" height="30"
viewBox="0 0 30 30">
<path d="M 3 7 A 1.0001 1.0001 0 1 0 3 9 L 27 9 A 1.0001 1.0001 0 1 0 27 7 L 3 7 z M 3 14 A 1.0001 1.0001 0 1 0 3 16 L 27 16 A 1.0001 1.0001 0 1 0 27 14 L 3 14 z M 3 21 A 1.0001 1.0001 0 1 0 3 23 L 27 23 A 1.0001 1.0001 0 1 0 27 21 L 3 21 z"></path>
</svg> 
                }
            </div>
        </header>
    );
}

export default Header;