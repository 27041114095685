import { faWallet } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Logo from "../assets/img/logo.png";
import "../styles/footer.scss"
function Footer() {
    return (
        <footer className="py-3 ">
            <div class="container">
                <nav>
                    <div className="logo">
                        <img src={Logo} width="100px"/>
                    </div>
                    <div className="footer-link">
                        <ul>
                            <li>
                                <a href="/">Home</a>
                            </li>
                            <li>
                                <a href="/about">About</a>
                            </li>
                            <li>
                                <a href="/review">Review</a>
                            </li>
                            <li>
                                <a href="/contact">Contact</a>
                            </li>
                        </ul>
                    </div>
                    <div className="social-media-link">
                        <a href="#">
                          <i className="fa fa-facebook"></i>
                        </a>
                        <a href="#">
                        <i className="fa fa-twitter"></i>
                        </a>
                        <a href="#">
                        <i className="fa fa-instagram"></i>
                        </a>
                    </div>
                </nav>
                <p>
                    <strong>DISCLAIMER:</strong> This site is general and not tailored to the specific goals of any individual. All investments carry risk. Some of those risks associated with cryptocurrencies include the following: prices may rise and fall, which means the value of your cryptos may go up or down over time and you may sell for more or less than you paid which is why it's best to use always use a Crypto IRA until you've met your profit taste. Past performance does not guarantee future results. Quantum Capital cannot guarantee, assure, or promise future market movement and prices. Even though Quantum Capital and its representatives are cryptocurrency specialists, we do not give financial advice. You have to invest according to your goals and profit taste. Additionally, Quantum Capital cannot provide tax or legal advice and will not advise as to the tax or legal consequences of purchasing or selling cryptocurrency or opening a Crypto IRA. Individuals should consult with their investment, legal, or tax pro
                </p>
                <div class="down-footer-link mt-4">
                    <nav>

                        <div className="footer-link">
                            <ul>
                                <li>
                                    <a href="">Privacy Policy</a>
                                </li>
                                <li>
                                    <a href="">Term & conditions</a>
                                </li>
                                <li>
                                    <a href="">Disclaimer</a>
                                </li>
                                <li>
                                    <a href="">California Privacy Notice</a>
                                </li>
                                <li>
                                    <a href="">ADA Compliance</a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
                <p className="text-center">© Copyright 2006 - 2023 Quantum Capital. All Rights Reserved.</p>
            </div>
        </footer>
    );
}

export default Footer;