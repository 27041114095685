import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination, HashNavigation, FreeMode } from "swiper";


import "../styles/home.scss";

import A1 from "../assets/img/about/about1.jpg";
import A2 from "../assets/img/about/about2.jpg";

import footerImg from "../assets/bottom-f.png";

import S1 from "../assets/img/section/s1.jpg";

import { faArrowRight, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Star from "../assets/icons/star.svg";
function Home() {
    return (
        <div>
            <section>
                <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="true">
                    <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    </div>
                    <div className="carousel-inner">
                        <div className="carousel-item active item1">
                            <div class="item h-100">
                                <div className="container h-100">
                                    <div className="row h-100 ">
                                        <div className="col-8 h-100 d-flex justify-content-center flex-column text-white">
                                            <h1 style={{ lineHeight: "60px",fontWeight:"normal" }}>“Quantum Capital<br /> is the Crypto Retirement Investment that I Recommend to American Patriots, and all Nations”</h1>
                                            <p className="my-3 p_name"> <span>— </span> PRESIDENT DONALD J. TRUMP </p>
                                            <div>
                                                <a className="btn btn-warning py-2" style={{ fontSize: "18px", width: "200px" }} href="https://web10.quantumcapital-inv.com/">Get Started</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item item2">
                            <div class="item h-100">
                                <div className="container h-100">
                                    <div className="row h-100 ">
                                        <div className="col-8 h-100 d-flex justify-content-center flex-column text-white">
                                                <h1 style={{ lineHeight: "60px",fontWeight:"normal" }}>“Quantum Capital<br /> is the Crypto IRA investment I recommend to protecting
                                                your retirement from Biden’s Inflation”</h1>
                                            <p className="my-3 p_name"> <span>— </span> SEAN HANNITY</p>
                                            <div>
                                                <a className="btn btn-warning py-2" href="https://web10.quantumcapital-inv.com/" style={{ fontSize: "18px", width: "200px" }} >Get Started</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>

            </section>
            <div class="elementor-section py-5" style={{ background: "#f0f9f4" }}>
                <div className="container d-flex flex-wrap justify-content-around">
                    <div>
                        <img src={"/assets/img/upload/a.png"} width="150px" className="pt-4" />
                    </div>
                    <div>
                        <img src={"/assets/img/upload/consumer.png"} width="150px" className="pt-4" />
                    </div>
                    <div>
                        <img  src={"/assets/img/upload/google.png"}  width="150px" className="pt-4" />
                    </div>
                    <div>
                        <img src={"/assets/img/upload/trustlink.png"} width="150px" className="pt-4" />
                    </div>
                    <div>
                        <img src={"/assets/img/upload/trustpilot.png"} width="150px" className="pt-4" />
                    </div>
                </div>
            </div>
            <div className="py-5 container inv mb-1">
                <h1 className="text-center" style={{ fontSize: "60px" }}>Which Crypto IRA Investment is Best for You?</h1>
            </div>
            <div className="container">
                <div className="row">
                    <div class="col-sm-3 m-auto">
                        <div className="invest_price p-3 shadow border mt-2">
                            <div className="heading">
                                <h3 class="py-3">XLM IRA</h3>
                            </div>
                            <div class="p-body mt-3">
                                <div className="underline-bottom d-flex justify-content-between align-items-center mb-3">
                                    <b>Monthly Interest</b>
                                    <p className="mb-0">20%</p>
                                </div>
                                <div className="underline-bottom d-flex justify-content-between align-items-center mb-3">
                                    <b>Minimum Deposit</b>
                                    <p className="mb-0">$500</p>
                                </div>
                                <div className="underline-bottom d-flex justify-content-between align-items-center mb-3">
                                    <b>Minimum XLM IRA</b>
                                    <p className="mb-0">$15,000</p>
                                </div>
                                <div className="underline-bottom d-flex justify-content-between align-items-center mb-3">
                                    <b>Capital Return</b>
                                    <p className="mb-0">Each Term</p>
                                </div>
                                <div className="underline-bottom d-flex justify-content-between align-items-center mb-3">
                                    <b>Contribution Size</b>
                                    <p className="mb-0">$500,000</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3 m-auto">
                        <div className="invest_price p-3 shadow border mt-2">
                            <div className="heading">
                                <h3 class="py-3">XRP IRA</h3>
                            </div>
                            <div class="p-body mt-3">
                                <div className="underline-bottom d-flex justify-content-between align-items-center mb-3">
                                    <b>Monthly Interest</b>
                                    <p className="mb-0">20%</p>
                                </div>
                                <div className="underline-bottom d-flex justify-content-between align-items-center mb-3">
                                    <b>Minimum Deposit</b>
                                    <p className="mb-0">$500</p>
                                </div>
                                <div className="underline-bottom d-flex justify-content-between align-items-center mb-3">
                                    <b>Minimum XRP IRA</b>
                                    <p className="mb-0">$15,000</p>
                                </div>
                                <div className="underline-bottom d-flex justify-content-between align-items-center mb-3">
                                    <b>Capital Return</b>
                                    <p className="mb-0">Each Term</p>
                                </div>
                                <div className="underline-bottom d-flex justify-content-between align-items-center mb-3">
                                    <b>Contribution Size</b>
                                    <p className="mb-0">$500,000</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3 m-auto">
                        <div className="invest_price p-3 shadow border mt-2">
                            <div className="heading">
                                <h3 class="py-3">Staking</h3>
                            </div>
                            <div class="p-body mt-3">
                                <div className="underline-bottom d-flex justify-content-between align-items-center mb-3">
                                    <b>Daily Interest</b>
                                    <p className="mb-0">5%</p>
                                </div>
                                <div className="underline-bottom d-flex justify-content-between align-items-center mb-3">
                                    <b>ROI</b>
                                    <p className="mb-0">Daily</p>
                                </div>
                                <div className="underline-bottom d-flex justify-content-between align-items-center mb-3">
                                    <b>Investment Term </b>
                                    <p className="mb-0">5 Years</p>
                                </div>
                                <div className="underline-bottom d-flex justify-content-between align-items-center mb-3">
                                   <b>Minimum XLM</b>
                                    <p className="mb-0">$120,000</p>
                                </div>
                                <div className="underline-bottom d-flex justify-content-between align-items-center mb-3">
                                    <b>Minimum XRP</b>
                                    <p className="mb-0">$120,000</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 mt-3 text-center mt-5 pt-5 d-flex align-items-center">
                        <div style={{ width: "50%" }}>
                            <div className="m-auto" style={{ width: "90%", height: "1px", background: "#cbecdb" }}></div>
                        </div>
                        <a className="btn btn-warning py-3" style={{ width: "200px" }} href="https://web10.quantumcapital-inv.com/">Connect Wallet</a>
                        <div style={{ width: "50%" }}>
                            <div className="m-auto" style={{ width: "90%", height: "1px", background: "#cbecdb" }}></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-4 pt-3">
                <div className="text-center mt-5">
                    <h1 style={{ fontSize: "50px" }}>As Seen On...</h1>
                </div>

                <a href="https://web10.quantumcapital-inv.com/" className="pb-4 px-4">
                    <Swiper
                        slidesPerView={4}
                        spaceBetween={30}
                        freeMode={true}
                        loop={true}
                        autoplay={true}
                        modules={[FreeMode, Autoplay]}
                        className="sponsored mt-5 m-auto" style={{width:"80%"}}
                    >
                        <SwiperSlide>
                            <div className="py-3">
                                <img src={"/assets/img/upload/inc_500.png"} width="150px" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="py-3">
                                <img src={"/assets/img/upload/fox.png"} width="150px" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="py-3">
                                <img src={"/assets/img/upload/fortune.png"} width="150px" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="py-3">
                                <img src={"/assets/img/upload/newsmax.png"} width="150px" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="py-3">
                                <img src={"/assets/img/upload/red_voice.png"} width="150px" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="py-3">
                                <img src={"/assets/img/upload/sean_han.png"} width="150px" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="py-3">
                                <img src={"/assets/img/upload/consumers.png"} width="150px" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="py-3">
                                <img src={"/assets/img/upload/trustpilot.png"} width="150px" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="py-3">
                                <img src={"/assets/img/upload/cinsumer_a.png"} width="150px" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="py-3">
                                <img src={"/assets/img/upload/trustpilot_n.png"} width="150px" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="py-3">
                                <img src={"/assets/img/upload/fox.png"} width="150px" />
                            </div>
                        </SwiperSlide>
                    </Swiper></a>
            </div>
            <div className="mt-5 container">
                <div className="row mt-5 about-home-reverse about-home p-sm">
                    <div className="col-sm-6 pp-a">
                        <h4 className="mb-4">WHY CRYPTO IRAS ?</h4>
                        <h1 className="mb-3"> Stability</h1>
                        <p className="mt-3">

                            Unlike the USA dollar, which has lost 90% of its purchasing power since 1971,
                            Regulated Cryptos backed by Gold & Sliver (XLM,XRP)
                            purchasing power  remains stable overtime. </p>
                        {/* our goal is to make it easy for you to get the benefit of owning Cryptos regulated by ISO 20022 and the security that comes with it.

                        The new ISO 20022 standard will be used for global payment systems, message exchanges (messaging formats), trading, and other activities.<br/> <a href="" className="text-dark text-decoration-none">
                                <span class="text-decoration-underline" style={{ marginRight: "8px" }}>Learn More</span> <FontAwesomeIcon className="text-warning" icon={faArrowRight} /></a></p>
                    </div> */}
                    </div>
                    <div className="col-sm-6  pp-a">
                        <img src={A1} width="100%" />
                    </div>
                </div>
            </div>
            <div className="mt-5 container">
                <div className="row mt-5 about-home">
                    <div className="col-sm-6 text-center  pp-a">
                        <img src={S1} height="250px;" className="img-sm" />
                    </div>
                    <div className="col-sm-6 pp-a">
                        {/* <h4 className="mb-4">Why Quantum Capital?</h4> */}
                        <h1 className="mb-3">Diversification</h1>
                        <p className="mt-3">
                            Protect your weath with an asset that can hedge against the damaging effects
                            of a recesssion and financial calamity
                            {/* Quantum Capital is the ISO 20022  Financial system all over the new world of finance with over a decade of experience in helping customers protect their retirement savings from taxation inflation. Whether you want to diversify your retirement assets into a Crytpo IRA or buy XLM and XRP directly, a Quantum Capital Specialist can help you seamlessly navigate the the process from beginning to end. <a href="" className="text-dark text-decoration-none">
                                <span class="text-decoration-underline" style={{ marginRight: "8px" }}>Learn More</span> <FontAwesomeIcon className="text-warning" icon={faArrowRight} /></a> */}
                        </p>
                    </div>
                </div>
            </div>
            <div className="mt-5 pt-4 container">
                <div className="text-center mt-5">
                    <h1>3 Step To A Crypto IRA</h1>
                </div>
                <div className="row mt-3 mt-p">
                    <div className="col-sm-4 p-4">
                        <p className="border shadow p-3" style={{ height: "250px" }}>   1. Open your IRA Wallet you can open your crypto IRA wallet by downloading a self custody crypto wallet e.g Exodus wallet, Trust Wallet from your google Playstore or apple Appstore for ios user.</p>
                    </div>
                    <div className="col-sm-4 p-4" >
                        <p className="border shadow p-3" style={{ height: "250px" }}>  2. You should fund your crypto IRA by rolling over assets from your existing retirement accounts or savings. For example, 401 (K), 403 (b), TSP, ISA, Superfund, savings or IRA account can all be rolled over or transferred.</p>
                    </div>
                    <div className="col-sm-4 p-4" >
                        <p className="border shadow p-3" style={{ height: "250px" }}>  3. Connect your Crypto IRA once your crypto IRA is funded, you can choose which crypto IRA you'd like to have as a part of your
                            retirement strategy</p>
                    </div>
                </div>
            </div>

            <div style={{ background: "#f0f9f4" }} className="py-5 mt-5">

                <Swiper
                    loop={true} slidesPerView={"auto"} navigation={true} centeredSlides={true} spaceBetween={30} pagination={{ clickable: true }} modules={[Autoplay, Pagination, Navigation]} autoplay={{ delay: 5000, disabledOnInteraction: false }}
                    className="container m-auto" id="review"
                >
                    <SwiperSlide className="container px-3" style={{ width: "100%" }}>
                        <div style={{ maxWidth: "600px" }} className="m-auto">
                            <div class="d-flex mb-3 pd-sm">
                                <img src={Star} style={{ marginRight: "6px" }} />
                                <img src={Star} style={{ marginRight: "6px" }} />
                                <img src={Star} style={{ marginRight: "6px" }} />
                                <img src={Star} style={{ marginRight: "6px" }} />
                                <img src={Star} style={{ marginRight: "6px" }} />
                            </div>
                            <h2>Our Investment is in Good Hands. </h2>
                            <p className="p-small">We are very impressed with the professionalism, knowledgeability, and promptness with which Quantum Capital handled our investment from our savings and retirement. They have shown that they are well organized. They have also amply demonstrated great patience in fielding our questions. We are confident our investment in crypto is in good hands with Quantum Capital.</p>
                            <h4>Micheal</h4>
                            <p>Reviewed on: Oct 13th, 2022</p>
                            <p><FontAwesomeIcon icon={faCheckCircle} className="text-warning" /> Verified Customer</p>
                            <img src={"/assets/img/upload/google.png"} width="120px" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="container px-3" style={{ width: "100%" }}>
                        <div style={{ maxWidth: "600px" }} className="m-auto">
                            <div class="d-flex mb-3">
                                <img src={Star} style={{ marginRight: "6px" }} />
                                <img src={Star} style={{ marginRight: "6px" }} />
                                <img src={Star} style={{ marginRight: "6px" }} />
                                <img src={Star} style={{ marginRight: "6px" }} />
                                <img src={Star} style={{ marginRight: "6px" }} />
                            </div>
                            <h2>Our Investment is in Good Hands. </h2>
                            <p className="p-small">We are very impressed with the professionalism, knowledgeability, and promptness with which Quantum Capital handled our investment from our savings and retirement. They have shown that they are well organized. They have also amply demonstrated great patience in fielding our questions. We are confident our investment in crypto is in good hands with Quantum Capital.</p>
                            <h4>Micheal</h4>
                            <p>Reviewed on: Oct 13th, 2022</p>
                            <p><FontAwesomeIcon icon={faCheckCircle} className="text-warning" /> Verified Customer</p>
                            <img src={"/assets/img/upload/trustpilot.png"} width="120px" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="container px-3" style={{ width: "100%" }}>
                        <div style={{ maxWidth: "600px" }} className="m-auto">
                            <div class="d-flex mb-3">
                                <img src={Star} style={{ marginRight: "6px" }} />
                                <img src={Star} style={{ marginRight: "6px" }} />
                                <img src={Star} style={{ marginRight: "6px" }} />
                                <img src={Star} style={{ marginRight: "6px" }} />
                                <img src={Star} style={{ marginRight: "6px" }} />
                            </div>
                            <h2>The Experience Was Expectional. </h2>
                            <p className="p-small">The experience from start to finish was exceptional. The representative was patient and considerate. I highly recommend Quantum Capital because they do not push you into something you’re really not sure about. I learned so much and now have a much better understanding of my investment.</p>
                            <h4>Elizabeth</h4>
                            <p>Reviewed on: march 11th, 2022</p>
                            <p><FontAwesomeIcon icon={faCheckCircle} className="text-warning" /> Verified Customer</p>
                            <img src={"/assets/img/upload/consumer.png"} width="120px" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="container px-3" style={{ width: "100%" }}>
                        <div style={{ maxWidth: "600px" }} className="m-auto">
                            <div class="d-flex mb-3">
                                <img src={Star} style={{ marginRight: "6px" }} />
                                <img src={Star} style={{ marginRight: "6px" }} />
                                <img src={Star} style={{ marginRight: "6px" }} />
                                <img src={Star} style={{ marginRight: "6px" }} />
                                <img src={Star} style={{ marginRight: "6px" }} />
                            </div>
                            <h2>Professional and Helpful. </h2>
                            <p className="p-small">Every person I’ve spoken with has been professional and helpful. Every question I have had had been answered and every step is explained. I appreciate that they have worked around my schedule for phone calls and the process was much smoother than I ever expected it to be and takes the stress out of such a big financial decision.</p>
                            <h4>Luis</h4>
                            <p>Reviewed on: june 1th, 2022</p>
                            <p><FontAwesomeIcon icon={faCheckCircle} className="text-warning" /> Verified Customer</p>
                            <img src={"/assets/img/upload/a.png"} width="120px" />
                        </div>
                    </SwiperSlide>

                </Swiper>
            </div>

            <div className="pt-5 sm_pb_none" style={{ background: "#08723c" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 d-flex justify-content-center flex-column">
                            <h1 className="text-white">Live A Rewarding And Secure Life. </h1>
                            <a className="btn btn-warning my-3" style={{ width: "200px" }} href="https://web10.quantumcapital-inv.com/">
                                Connect Wallet</a>
                        </div>
                        <div className="col-sm-6">
                            <img src={footerImg} width="100%" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;