function About() {
    return (
        <div style={{ background: "#f3f3f3" }}>
            <div class="container">
                <div className="text-center pt-5">
                    <p>ABOUT</p>
                    <h1>Why Quantum Capital?</h1>
                </div>
                <div className="mt-5">
                    <h1>Who We Are</h1>
                    <p className="mt-3">
                        Quantum Capital is the ISO 20022  Financial system all over the new world of finance with over a decade of experience in helping customers protect their retirement savings from taxation inflation. Whether you want to diversify your retirement assets into a Crytpo IRA or buy XLM and XRP directly, a Quantum Capital Specialist can help you seamlessly navigate the the process from beginning to end.

                        Quantum Financial's long-standing reputation for excellent asset security, and high ethical standards, along with Crypto education and resources position Quantum Capital as a preferred Cryptocurrency provider.

                        Quantum Capital is the only crypto IRA Investment recommended by major leaders like President Donald J. Trump, Sean Hannity,  Chuck Norris, and Ben Stein.

                        Quantum Capital is a leader in the cryptocurrency industry, with over a decade of experience helping customers protect their retirement savings. If you have an IRA, 401 (k), TSP, or similar tax-advantaged retirement account, aQuantum Capital Specialist can help you protect those assets with a Crypto IRA.


                        We also offer direct sales of XLM and XRP to our customers. If you have a savings account, a Quantum Capital Specialist can help you diversify those funds with cryptocurrency. Whether you want to roll over retirement assets into a Crypto IRA or buy XLM and XRP directly, a Quantum Capital Specialist can help you navigate each step of the seamless process from beginning to end.

                        Quantum Capital is rated A+ by the Better Business Bureau and Triple-A by Business Consumer Alliance. Our quality customer service, dependability, and ethical business practices have resulted in thousands of satisfied customers and numerous positive customer reviews.


                        Purchase your crypto currencies with confidence knowing that if your circumstances change, Quantum Capital offers a buy back program guaranteed at the highest price. We provide you with the best possible Cryptocurrency available and are now proud to offer more comfort and security with your purchase.
                    </p>
                </div>
                <div className="mt-5">
                    <h1>About Us</h1>
                    <p className="mt-3">
                        Quantum Capital, our goal is to make it easy for you to get the benefit of owning Cryptos regulated by ISO 20022 and the security that comes with it.

                        The new ISO 20022 standard will be used for global payment systems, message exchanges (messaging formats), trading, and other activities.

                        Regulated ISO 20022 Cryptos like XRP, XLM, XDC, ALGO, IOTA, SHX, and HBAR has a value of 10X, 100X, 1,000X, and Even More.

                        Cryptocurrency ownership is an important part of your overall retirement savings strategy.
                    </p>
                </div>
                <div className="row py-5">
                    <div className="col-sm-12">
                        <img src="https://goldco.com/wp-content/uploads/2022/10/2022-Awards-Bar-Desktop_1.png" width="100%" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;