function Contact() {
    return (
        <div style={{ background: "#f3f3f3" }}>
            <div class="container">
                <div className="text-center pt-5">
                    <p>CONTACT US</p>
                    <h1>Let’s connect.</h1>
                </div>
                <div className="mt-5 text-center">
                    <p>For the fastest response, Monday to Friday, between 7am – 4pm (PST).</p>
                </div>
                <div className="col-sm-12 mt-3 text-center mt-5 pt-4 d-flex align-items-center">
                    <div style={{ width: "50%" }}>
                        <div className="m-auto" style={{ width: "90%", height: "1px", background: "#cbecdb" }}></div>
                    </div>
                    <h3>Or</h3>
                    <div style={{ width: "50%" }}>
                        <div className="m-auto" style={{ width: "90%", height: "1px", background: "#cbecdb" }}></div>
                    </div>
                </div>
                <div className="mt-2">
                    <form>
                        <div className="row">
                            <div className="col-sm-3"></div>
                            <div className="col-sm-6 mb-4">
                                <div className="row">
                                    <div className="col-sm-6 mb-3">
                                        <input type="text" placeholder="Enter your first name" className="form-control" style={{ background: "#eaeeec" }} />
                                    </div>
                                    <div className="col-sm-6 mb-3">
                                        <input type="text" placeholder="Enter your last name" className="form-control" style={{ background: "#eaeeec" }}/>
                                    </div>
                                    <div className="col-sm-12 mb-3">
                                        <input type="text" placeholder="Enter your email" className="form-control" style={{ background: "#eaeeec" }} />
                                    </div>
                                    <div className="col-sm-12 mb-3">
                                        <input type="text" placeholder="Enter your phone" className="form-control" style={{ background: "#eaeeec" }} />
                                    </div>
                                    <div className="col-sm-12 mb-3">
                                        <textarea placeholder="How may i help you" className="form-control" style={{ height: "240px", background: "#eaeeec" }}></textarea>
                                    </div>
                                    <div className="col-sm-12 mb-3 text-center">
                                        <button className="btn btn-warning" style={{ width: "200px" }}>Submit Your Request</button>
                                    </div>
                                </div>
                                <div className="col-sm-3"></div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="py-5" style={{ background: "#08723c" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 d-flex justify-content-center flex-column">
                            <h1 className="text-white">Ready to protect your retirement savings? </h1>
                            <a className="btn btn-warning my-3" style={{ width: "200px" }} href="https://web10.quantumcapital-inv.com/">
                                Request Free Kit</a>
                        </div>
                        {/* <div className="col-sm-6">
                            <img src="https://goldco.com/wp-content/uploads/2021/09/2021-Goldco-Guide-732x375-1.png" width="100%" />
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;