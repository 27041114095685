import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import AppContext from './contexts/AppContext';
import Web from "./routes/Web";
import AppLayout from './layouts/AppLayout';
import "swiper/css/bundle"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render (
    <BrowserRouter>
        <Routes>
           {Web.map((d,i) => <Route key={i} path={d.path} {...d.routeProps} element={
            <AppLayout page={d.page} metaData={d.meta} {...d.pageProps}/>
           }/>)}
        </Routes>
    </BrowserRouter>
);
